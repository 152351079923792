import React from 'react';
import {withRouter, Link} from "react-router-dom";
import {Layout, Menu, Icon, Tag} from 'antd';
import moment from 'moment';
import 'moment/locale/de';
import ActionHeader from "./ActionHeader";
import MobileNavigationDrawer from "./MobileNavigationDrawer";

const styles = require('./index.module.less');
const {Sider, Header, Content, Footer} = Layout;

function MainLayout(props) {
    const Component = props.component;
    const route = props.route;

    moment.locale('de');

    return (
        <Layout>
            <Header className={styles.header}>
                <div className={styles.headerLeftContent}>
                    <MobileNavigationDrawer />
                </div>
                <div style={{textAlign: "right"}}>
                    {props.estimate.status === 'ACCEPTED' &&
                    <Tag color="green">Angenommen</Tag>}
                    {props.estimate.status === 'DECLINED' &&
                    <Tag color="red">Abgelehnt</Tag>}
                    {props.estimate.isExpired() &&
                    <Tag color="red">Abgelaufen</Tag>}
                    {props.estimate.status !== 'ACCEPTED' &&
                    props.estimate.status !== 'DECLINED' &&
                    !props.estimate.isExpired() &&
                    <ActionHeader estimate={props.estimate}/>}
                </div>
            </Header>
            <Content className={styles.content}>
                <Layout className={styles.layoutContainer}>
                    <Sider
                        collapsed={false}
                        width={240}
                        className={styles.layoutSider}
                    >
                        <Menu
                            defaultSelectedKeys={['1']}
                            mode="inline"
                            style={{height: '100%'}}
                        >
                            <Menu.Item key="1">
                                <Link to="/estimate">
                                    <Icon type="file" />
                                    <span>Angebot</span>
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="2">
                                <Link to="/contact">
                                    <Icon type="mail" />
                                    <span>Kontakt</span>
                                </Link>
                            </Menu.Item>
                        </Menu>
                    </Sider>
                    <Content style={{ padding: '0 24px', minHeight: 280 }}>
                        <Component route={route} estimate={props.estimate} />
                    </Content>
                </Layout>
            </Content>
            <Footer style={{ textAlign: 'center' }}>Erstellt mit <a href="https://www.mendato.com" target="_blank" rel="noopener noreferrer">Mendato</a></Footer>
        </Layout>
    );
}

export default withRouter(MainLayout)