import {
    commitMutation
} from 'react-relay'
import graphql from 'babel-plugin-relay/macro';
import environment from '../Environment'
import {
    DeclineEstimateMutation,
    DeclineEstimateMutationVariables,
    EstimateDeclineReason
} from "./__generated__/DeclineEstimateMutation.graphql";

const mutation = graphql`mutation DeclineEstimateMutation($input: DeclineEstimateInput!) {
    declineEstimate(input: $input) {
      estimate {
        id
        status
      }
    }
  }
`;

export default (webToken, reason: EstimateDeclineReason, note: string|null|undefined, callback) => {
    const variables: DeclineEstimateMutationVariables = {
        input: {
            webToken: webToken,
            reason: reason,
            note: note
        }
    };

    commitMutation<DeclineEstimateMutation>(
        environment,
        {
            mutation,
            variables,
            onCompleted: (response) => {
                const estimate = response.declineEstimate?.estimate;
                callback(estimate)
            },
            onError: err => console.error(err),
        },
    )
}