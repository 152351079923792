/* tslint:disable */
/* eslint-disable */
/* @relayHash df16a3311cb6c3dbcef8987c49f20c7d */

import { ConcreteRequest } from "relay-runtime";
export type EstimateDeclineReason = "FOUND_OTHER_SUPPLIER" | "NO_MORE_DEMAND" | "OTHER_REASON" | "PRICE_TOO_HIGH" | "QUALITY_TOO_LOW" | "%future added value";
export type EstimateStatus = "ACCEPTED" | "COMPLETED" | "DECLINED" | "DRAFT" | "%future added value";
export type DeclineEstimateInput = {
    webToken: string;
    reason: EstimateDeclineReason;
    note?: string | null;
};
export type DeclineEstimateMutationVariables = {
    input: DeclineEstimateInput;
};
export type DeclineEstimateMutationResponse = {
    readonly declineEstimate: {
        readonly estimate: {
            readonly id: string;
            readonly status: EstimateStatus;
        } | null;
    } | null;
};
export type DeclineEstimateMutation = {
    readonly response: DeclineEstimateMutationResponse;
    readonly variables: DeclineEstimateMutationVariables;
};



/*
mutation DeclineEstimateMutation(
  $input: DeclineEstimateInput!
) {
  declineEstimate(input: $input) {
    estimate {
      id
      status
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "DeclineEstimateInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "declineEstimate",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeclineEstimatePayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "estimate",
        "storageKey": null,
        "args": null,
        "concreteType": "Estimate",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "status",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "DeclineEstimateMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "DeclineEstimateMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "DeclineEstimateMutation",
    "id": null,
    "text": "mutation DeclineEstimateMutation(\n  $input: DeclineEstimateInput!\n) {\n  declineEstimate(input: $input) {\n    estimate {\n      id\n      status\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '16eec8c60487d102bdaa055d73b915a8';
export default node;
