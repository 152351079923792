export default class EstimateItem {
    key: string;
    id: string;
    productId: string|null;
    title: string;
    description: string|null;
    quantity: number;
    unitPrice: number;
    taxRate: number;
    unit: string;
    constructor(estimateItem) {
        this.id = (estimateItem && estimateItem.id !== undefined) ? estimateItem.id : Math.random().toString(36).substr(2);
        this.key = this.id;
        if (estimateItem && estimateItem.productId) {
            this.productId = estimateItem.productId;
        } else if (estimateItem && estimateItem.product && estimateItem.product.id) {
            this.productId = estimateItem.product.id;
        } else {
            this.productId = null;
        }
        this.title = (estimateItem && estimateItem.title !== undefined) ? estimateItem.title : null;
        this.description = (estimateItem && estimateItem.description !== undefined) ? estimateItem.description : null;
        this.quantity = (estimateItem && estimateItem.quantity !== undefined) ? estimateItem.quantity : null;
        this.unitPrice = (estimateItem && estimateItem.unitPrice !== undefined) ? estimateItem.unitPrice : null;
        this.taxRate = (estimateItem && estimateItem.taxRate !== undefined) ? estimateItem.taxRate : null;
        this.unit = (estimateItem && estimateItem.unit !== undefined) ? estimateItem.unit : null;
    }
};