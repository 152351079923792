import React from 'react';
import Estimate from "../../../models/Estimate";
import accounting from "accounting";
import {Table} from "antd";
import EstimateItem from "../../../models/EstimateItem";

const styles = require('./index.module.less');

export interface EstimateItemsTableProps {
    estimate: Estimate;
}

function EstimateItemsTable(props: EstimateItemsTableProps) {
    return (
        <>
        <Table
            className={styles.fullSizeTable}
            dataSource={props.estimate.items}
            columns={[
                {
                    title: 'Titel',
                    dataIndex: 'title',
                    render: (text, estimateItem: EstimateItem, index) => (<>
                        <strong>{estimateItem.title}</strong><br/>
                        {estimateItem.description}
                    </>)
                },
                {
                    title: 'Menge',
                    dataIndex: 'quantity',
                    render: (text, estimateItem: EstimateItem, index) => (
                        <div style={{whiteSpace: "nowrap"}}>
                            {accounting.formatMoney(estimateItem.quantity, "", 2, ".", ",") + (estimateItem.unit ? " " + estimateItem.unit : "")}
                        </div>
                    )
                },
                {
                    title: 'Einheit',
                    dataIndex: 'unitPrice',
                    render: (text, estimateItem: EstimateItem, index) => (
                        <div style={{whiteSpace: "nowrap"}}>
                            {(accounting.formatMoney(estimateItem.unitPrice / 100, "", 2, ".", ",") + " €")}
                        </div>
                    )
                },
                {
                    title: 'Steuersatz',
                    dataIndex: 'taxRate',
                    render: (text, estimateItem: EstimateItem, index) => (
                        <div style={{whiteSpace: "nowrap"}}>
                            {estimateItem.taxRate + " %"}
                        </div>
                    )
                },
                {
                    title: 'Betrag',
                    dataIndex: 'amount',
                    render: (text, estimateItem: EstimateItem, index) => (
                        <div style={{whiteSpace: "nowrap"}}>
                            {accounting.formatMoney(accounting.toFixed(estimateItem.unitPrice * estimateItem.quantity, 0) / 100, "", 2, ".", ",") + " €"}
                        </div>
                    )
                },
            ]}
            pagination={false}
        />
            <Table
                className={styles.mobileTable}
                dataSource={props.estimate.items}
                columns={[
                    {
                        title: 'Titel',
                        dataIndex: 'title',
                        render: (text, estimateItem: EstimateItem, index) => (<>
                            <strong>{accounting.formatMoney(estimateItem.quantity, "", 2, ".", ",") + (estimateItem.unit ? " " + estimateItem.unit : " x")} {estimateItem.title}</strong><br/>
                            {estimateItem.description}
                        </>)
                    },
                    {
                        title: 'Betrag',
                        dataIndex: 'amount',
                        render: (text, estimateItem: EstimateItem, index) => (
                            <div style={{whiteSpace: "nowrap"}}>
                                <strong>{accounting.formatMoney(accounting.toFixed(estimateItem.unitPrice * estimateItem.quantity, 0) / 100, "", 2, ".", ",") + " €"}</strong><br/>
                                USt.: {estimateItem.taxRate + " %"}
                            </div>
                        )
                    },
                ]}
                pagination={false}
            />
        </>
    );
}

export default EstimateItemsTable;