import React from 'react'
import Routes from "./pages/Routes";
import graphql from "babel-plugin-relay/macro";
import environment from "./Environment";
import Estimate from "./models/Estimate";
import {Result, Spin} from "antd";
import {QueryRenderer} from "react-relay";
import {AppEstimateQuery} from "./__generated__/AppEstimateQuery.graphql";

const query = graphql`            
    query AppEstimateQuery (
        $webToken: ID!
    ) {
        estimate(webToken: $webToken) {
            id
            company {
              id
              companyName
              legalForm
              streetAddress
              zip
              city
              taxNumber
              vatId
              email
              phone
              fax
              iban
              bic
              registerCourt
              companyRegistrationNumber
              managingDirectors {
                id
                name
              }
              logoUrl
            }
            customer {
              id
              type
              salutation
              firstName
              lastName
              companyName
              streetAddress
              zip
              city
            }
            status
            number
            numberPrefix
            items {
              id
              title
              description
              quantity
              unitPrice
              taxRate
              unit
            }
            estimateDate
            validityDate
            hasKleinunternehmerregelung
            estimatePdf
            webToken
            webEnabled
            declineReason
            declineNote
            acceptedAt
            declinedAt
        }
    }`;

const App: React.FC = () => {
    let pathNames = window.location.pathname.split('/');
    let webToken: string|null = null;
    if (pathNames.length > 1 && pathNames[1] !== "")  {
        webToken = pathNames[1];
    }

    return (
        <div className="App">
            {webToken == null &&
            <Result
                status="404"
                title="404"
                subTitle="Diese Seite konnte nicht gefunden werden."
            />}
            {webToken &&
            <QueryRenderer<AppEstimateQuery>
                environment={environment}
                query={query}
                variables={{
                    webToken: webToken,
                }}
                render={({error, props}) => {
                    if (error) {
                        if (error.message === "WebDisabled") {
                            return <Result
                                status="403"
                                title="Dieses Angebot wurde deakiviert"
                            />;
                        }
                        return <p>{error.message}</p>;
                    } else if (props) {
                        if (!props.estimate) {
                            return <Result
                                status="404"
                                title="404"
                                subTitle="Dieses Angebot konnte nicht gefunden werden."
                            />
                        }
                        let estimate = new Estimate(props.estimate);
                        return <Routes estimate={estimate}/>;
                    }
                    return <div style={{textAlign: 'center', padding: '40px 0'}}>
                        <Spin size="large"/>
                    </div>;
                }}
            />}
        </div>
    );
};

export default App;
