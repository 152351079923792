/* tslint:disable */
/* eslint-disable */
/* @relayHash 09a335044388510e3b03aa25a6d3083a */

import { ConcreteRequest } from "relay-runtime";
export type EstimateStatus = "%future added value" | "ACCEPTED" | "COMPLETED" | "DECLINED" | "DRAFT" | "%future added value";
export type AcceptEstimateInput = {
    webToken: string;
};
export type AcceptEstimateMutationVariables = {
    input: AcceptEstimateInput;
};
export type AcceptEstimateMutationResponse = {
    readonly acceptEstimate: {
        readonly estimate: {
            readonly id: string;
            readonly status: EstimateStatus;
        } | null;
    } | null;
};
export type AcceptEstimateMutation = {
    readonly response: AcceptEstimateMutationResponse;
    readonly variables: AcceptEstimateMutationVariables;
};



/*
mutation AcceptEstimateMutation(
  $input: AcceptEstimateInput!
) {
  acceptEstimate(input: $input) {
    estimate {
      id
      status
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "AcceptEstimateInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "acceptEstimate",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AcceptEstimatePayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "estimate",
        "storageKey": null,
        "args": null,
        "concreteType": "Estimate",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "status",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "AcceptEstimateMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "AcceptEstimateMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "AcceptEstimateMutation",
    "id": null,
    "text": "mutation AcceptEstimateMutation(\n  $input: AcceptEstimateInput!\n) {\n  acceptEstimate(input: $input) {\n    estimate {\n      id\n      status\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = 'ac3f6363965880335276856c1e113da4';
export default node;
