import React from 'react';
import moment from 'moment';
import {Alert} from "antd";
import Estimate from "../../models/Estimate";

export interface EstimateAlertBarProps {
    estimate: Estimate,
}

function EstimateAlertBar(props: EstimateAlertBarProps) {
    let alertElement: any|null;

    if (props.estimate.status === "ACCEPTED") {
        alertElement = <Alert style={{marginBottom: 16}} message="Dieses Angebot wurde angenommen" type="success" showIcon />;
    } else if (props.estimate.status === "DECLINED") {
        alertElement = <Alert style={{marginBottom: 16}} message="Dieses Angebot wurde abgelehnt" type="error" showIcon />;
    } else {
        let message;
        let type;

        if (moment(moment().format("YYYY-MM-DD")).isSame(props.estimate.validityDate)) {
            message = "Dieses Angebot läuft heute ab";
            type = "info";
        } else if (moment(moment().format("YYYY-MM-DD")).add(1, 'days').isSame(props.estimate.validityDate)) {
            message = "Dieses Angebot läuft morgen ab";
            type = "info";
        } else if (moment(moment().format("YYYY-MM-DD")).isAfter(props.estimate.validityDate)) {
            message = "Dieses Angebot ist abgelaufen";
            type = "error";
        } else {
            let a = moment(props.estimate.validityDate).add(1, 'days');
            let b = moment();
            message = "Dieses Angebot ist noch " + a.diff(b, 'days') + " Tage gültig";
            type = "info";
        }

        alertElement = <Alert style={{marginBottom: 16}} message={message} type={type} showIcon />;
    }

    return (alertElement);
}

export default React.memo(EstimateAlertBar, function areEqual(prevProps, nextProps) {
    return JSON.stringify(prevProps) === JSON.stringify(nextProps);
});