import CompanyManagingDirector from "./CompanyManagingDirector";

export default class Company {
    id: string;
    companyName: string;
    legalForm: string;
    streetAddress: string|null;
    zip: string|null;
    city: string|null;
    taxNumber: string|null;
    vatId: string|null;
    email: string|null;
    phone: string|null;
    fax: string|null;
    iban: string|null;
    bic: string|null;
    registerCourt: string|null;
    companyRegistrationNumber: string|null;
    managingDirectors: CompanyManagingDirector[];
    logoUrl: string|null;
    constructor(company) {
        this.id = company.id;
        this.companyName = company.companyName;
        this.legalForm = company.legalForm;
        this.streetAddress = company.streetAddress;
        this.zip = company.zip;
        this.city = company.city;
        this.taxNumber = company.taxNumber;
        this.vatId = company.vatId;
        this.email = company.email;
        this.phone = company.phone;
        this.fax = company.fax;
        this.iban = company.iban;
        this.bic = company.bic;
        this.registerCourt = company.registerCourt;
        this.companyRegistrationNumber = company.companyRegistrationNumber;
        this.managingDirectors = company.managingDirectors.map(managingDirector => new CompanyManagingDirector(managingDirector));
        this.logoUrl = company.logoUrl;
    }
}