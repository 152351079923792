/* tslint:disable */
/* eslint-disable */
/* @relayHash f0754ea38b269bbfecfc18b897e9126a */

import { ConcreteRequest } from "relay-runtime";
export type CustomerType = "COMPANY" | "PERSON" | "%future added value";
export type EstimateDeclineReason = "%future added value" | "FOUND_OTHER_SUPPLIER" | "NO_MORE_DEMAND" | "OTHER_REASON" | "PRICE_TOO_HIGH" | "QUALITY_TOO_LOW" | "%future added value";
export type EstimateStatus = "%future added value" | "%future added value" | "ACCEPTED" | "COMPLETED" | "DECLINED" | "DRAFT" | "%future added value";
export type LegalForm = "DE_EINGETRAGENER_KAUFMANN" | "DE_EINZELUNTERNEHMER" | "DE_GBR" | "DE_GMBH" | "DE_UG" | "%future added value";
export type AppEstimateQueryVariables = {
    webToken: string;
};
export type AppEstimateQueryResponse = {
    readonly estimate: {
        readonly id: string;
        readonly company: {
            readonly id: string;
            readonly companyName: string | null;
            readonly legalForm: LegalForm | null;
            readonly streetAddress: string | null;
            readonly zip: string | null;
            readonly city: string | null;
            readonly taxNumber: string | null;
            readonly vatId: string | null;
            readonly email: string | null;
            readonly phone: string | null;
            readonly fax: string | null;
            readonly iban: string | null;
            readonly bic: string | null;
            readonly registerCourt: string | null;
            readonly companyRegistrationNumber: string | null;
            readonly managingDirectors: ReadonlyArray<{
                readonly id: string;
                readonly name: string;
            } | null> | null;
            readonly logoUrl: unknown | null;
        } | null;
        readonly customer: {
            readonly id: string;
            readonly type: CustomerType;
            readonly salutation: string | null;
            readonly firstName: string | null;
            readonly lastName: string | null;
            readonly companyName: string | null;
            readonly streetAddress: string | null;
            readonly zip: string | null;
            readonly city: string | null;
        } | null;
        readonly status: EstimateStatus;
        readonly number: number;
        readonly numberPrefix: string | null;
        readonly items: ReadonlyArray<{
            readonly id: string;
            readonly title: string | null;
            readonly description: string | null;
            readonly quantity: number | null;
            readonly unitPrice: number | null;
            readonly taxRate: number | null;
            readonly unit: string | null;
        } | null> | null;
        readonly estimateDate: unknown | null;
        readonly validityDate: unknown | null;
        readonly hasKleinunternehmerregelung: boolean;
        readonly estimatePdf: string | null;
        readonly webToken: string;
        readonly webEnabled: boolean;
        readonly declineReason: EstimateDeclineReason | null;
        readonly declineNote: string | null;
        readonly acceptedAt: unknown | null;
        readonly declinedAt: unknown | null;
    } | null;
};
export type AppEstimateQuery = {
    readonly response: AppEstimateQueryResponse;
    readonly variables: AppEstimateQueryVariables;
};



/*
query AppEstimateQuery(
  $webToken: ID!
) {
  estimate(webToken: $webToken) {
    id
    company {
      id
      companyName
      legalForm
      streetAddress
      zip
      city
      taxNumber
      vatId
      email
      phone
      fax
      iban
      bic
      registerCourt
      companyRegistrationNumber
      managingDirectors {
        id
        name
      }
      logoUrl
    }
    customer {
      id
      type
      salutation
      firstName
      lastName
      companyName
      streetAddress
      zip
      city
    }
    status
    number
    numberPrefix
    items {
      id
      title
      description
      quantity
      unitPrice
      taxRate
      unit
    }
    estimateDate
    validityDate
    hasKleinunternehmerregelung
    estimatePdf
    webToken
    webEnabled
    declineReason
    declineNote
    acceptedAt
    declinedAt
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "webToken",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "companyName",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "streetAddress",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "zip",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "city",
  "args": null,
  "storageKey": null
},
v6 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "estimate",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "webToken",
        "variableName": "webToken"
      }
    ],
    "concreteType": "Estimate",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "company",
        "storageKey": null,
        "args": null,
        "concreteType": "Company",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "legalForm",
            "args": null,
            "storageKey": null
          },
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "taxNumber",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "vatId",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "email",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "phone",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "fax",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "iban",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "bic",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "registerCourt",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "companyRegistrationNumber",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "managingDirectors",
            "storageKey": null,
            "args": null,
            "concreteType": "ManagingDirector",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "name",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "logoUrl",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "customer",
        "storageKey": null,
        "args": null,
        "concreteType": "Customer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "type",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "salutation",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "firstName",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "lastName",
            "args": null,
            "storageKey": null
          },
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/)
        ]
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "status",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "number",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "numberPrefix",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "items",
        "storageKey": null,
        "args": null,
        "concreteType": "EstimateItem",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "title",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "description",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "quantity",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "unitPrice",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "taxRate",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "unit",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "estimateDate",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "validityDate",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "hasKleinunternehmerregelung",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "estimatePdf",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "webToken",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "webEnabled",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "declineReason",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "declineNote",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "acceptedAt",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "declinedAt",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "AppEstimateQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v6/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "AppEstimateQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v6/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "AppEstimateQuery",
    "id": null,
    "text": "query AppEstimateQuery(\n  $webToken: ID!\n) {\n  estimate(webToken: $webToken) {\n    id\n    company {\n      id\n      companyName\n      legalForm\n      streetAddress\n      zip\n      city\n      taxNumber\n      vatId\n      email\n      phone\n      fax\n      iban\n      bic\n      registerCourt\n      companyRegistrationNumber\n      managingDirectors {\n        id\n        name\n      }\n      logoUrl\n    }\n    customer {\n      id\n      type\n      salutation\n      firstName\n      lastName\n      companyName\n      streetAddress\n      zip\n      city\n    }\n    status\n    number\n    numberPrefix\n    items {\n      id\n      title\n      description\n      quantity\n      unitPrice\n      taxRate\n      unit\n    }\n    estimateDate\n    validityDate\n    hasKleinunternehmerregelung\n    estimatePdf\n    webToken\n    webEnabled\n    declineReason\n    declineNote\n    acceptedAt\n    declinedAt\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '888140e523862d8a76a6bd175f49671d';
export default node;
