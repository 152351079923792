import React, {useEffect} from 'react';
import {withRouter} from "react-router-dom";
import Estimate from "../models/Estimate";
import {Descriptions, Typography} from "antd";

const {Title} = Typography;

function ContactPage(props) {
    let estimate = new Estimate(props.estimate);

    useEffect(() => {
        document.title = "Kontakt | Angebot";
    }, []);

    return (
        <div>
            <Title level={3}>Kontakt</Title>
            <p style={{marginTop: 24}}><strong>{estimate.company?.companyName}</strong></p>
            <p>{estimate.company?.streetAddress}<br/>
                {estimate.company?.zip} {estimate.company?.city}
            </p>
            {(estimate.company?.email || estimate.company?.phone || estimate.company?.fax) &&
            <Descriptions size={"small"} style={{marginTop: 24}}>
                {estimate.company?.email &&
                <Descriptions.Item label="E-Mail" span={3}>{estimate.company?.email}</Descriptions.Item>}
                {estimate.company?.phone &&
                <Descriptions.Item label="Telefon" span={3}>{estimate.company?.phone}</Descriptions.Item>}
                {estimate.company?.fax &&
                <Descriptions.Item label="Fax" span={3}>{estimate.company?.fax}</Descriptions.Item>}
            </Descriptions>}
        </div>
    );
}

export default withRouter(ContactPage);