import {
    Environment,
    Network,
    RecordSource,
    Store,
} from 'relay-runtime';

const endpoint = process.env.REACT_APP_ESTIMATE_API_URL!;

function fetchQuery(
    operation: any,
    variables: any,
) {
    return fetch(endpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            query: operation.text,
            variables,
        }),
    }).then(response => {
        return response.json();
    }).then(json => {
        if (json && json.errors) {
            if (json.errors[0].extensions && json.errors[0].extensions.type) {
                if ('NotAuthorized' === json.errors[0].extensions.type) {
                    throw new Error("NotAuthorized");
                }
            }
            if (json.errors[0].extensions && json.errors[0].extensions.type) {
                if ('WebDisabled' === json.errors[0].extensions.type) {
                    throw new Error("WebDisabled");
                }
            }
        }
        return json;
    });
}

const environment = new Environment({
    network: Network.create(fetchQuery),
    store: new Store(new RecordSource()),
});

export default environment;