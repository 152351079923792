import React, {FunctionComponent} from 'react';
import { Modal, Form, Input, Radio } from 'antd';
import {FormComponentProps} from "antd/lib/form";

const {TextArea} = Input;

export interface DeclineModalFormProps extends FormComponentProps{
    visible?: boolean;
    confirmLoading?: boolean;
    onCancel?: (e: React.MouseEvent<HTMLElement>) => void;
    onDecline?: (e: React.MouseEvent<HTMLElement>) => void;
}

const DeclineModalForm: FunctionComponent<DeclineModalFormProps> = (props: DeclineModalFormProps) => {
    const {visible, confirmLoading, onCancel, onDecline, form} = props;
    const {getFieldDecorator} = form;
    const radioStyle = {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
    };

    return (
        <Modal
            visible={visible}
            title="Angebot ablehnen"
            okText="Ablehnen"
            cancelText="Abbrechen"
            onCancel={onCancel}
            onOk={onDecline}
            confirmLoading={confirmLoading}
        >
            <Form layout="vertical">
                <Form.Item label="Grund">
                    {getFieldDecorator('reason', {
                        rules: [{required: true, message: 'Bitte gib einen Grund an'}]
                    })(
                        <Radio.Group>
                            <Radio style={radioStyle} value={"PRICE_TOO_HIGH"}>
                                Preis zu hoch
                            </Radio>
                            <Radio style={radioStyle} value={"QUALITY_TOO_LOW"}>
                                Qualität zu niedrig
                            </Radio>
                            <Radio style={radioStyle} value={"FOUND_OTHER_SUPPLIER"}>
                                Anderen Lieferanten gefunden
                            </Radio>
                            <Radio style={radioStyle} value={"NO_MORE_DEMAND"}>
                                Keinen Bedarf mehr
                            </Radio>
                            <Radio style={radioStyle} value={"OTHER_REASON"}>
                                Anderer Grund
                            </Radio>
                        </Radio.Group>,
                    )}
                </Form.Item>
                <Form.Item label="Anmerkung">
                    {getFieldDecorator('note')(<TextArea />)}
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default Form.create<DeclineModalFormProps>({name: 'decline'})(DeclineModalForm);