import Customer from "./Customer";
import accounting from "accounting";
import moment from 'moment';
import EstimateItem from "./EstimateItem";
import Company from "./Company";

export default class Estimate {
    id: string;
    company: Company|null;
    customer: Customer|null;
    status: string;
    number: number|null;
    numberPrefix: string;
    estimateDate: string;
    validityDate: string;
    hasKleinunternehmerregelung: boolean;
    estimatePdf: string|null;
    webToken: string;
    webEnabled: boolean;
    declineReason: string|null;
    declineNote: string|null;
    acceptedAt: string|null;
    declinedAt: string|null;
    items: EstimateItem[];

    constructor(estimate) {
        this.id = estimate.id === undefined ? null : estimate.id;
        this.company = estimate.company ? new Company(estimate.company) : null;
        this.customer = estimate.customer ? new Customer(estimate.customer) : null;
        this.status = estimate.status === undefined ? null : estimate.status;
        this.number = estimate.number === undefined ? null : estimate.number;
        this.numberPrefix = estimate.numberPrefix === undefined ? null : estimate.numberPrefix;
        this.estimateDate = estimate.estimateDate === undefined ? null : estimate.estimateDate;
        this.validityDate = estimate.validityDate === undefined ? null : estimate.validityDate;
        this.hasKleinunternehmerregelung = estimate.hasKleinunternehmerregelung === undefined ? null : estimate.hasKleinunternehmerregelung;
        this.estimatePdf = estimate.estimatePdf === undefined ? null : estimate.estimatePdf;
        this.webToken = estimate.webToken === undefined ? null : estimate.webToken;
        this.webEnabled = estimate.webEnabled;
        this.declineReason = estimate.declineReason === undefined ? null : estimate.declineReason;
        this.declineNote = estimate.declineNote === undefined ? null : estimate.declineNote;
        this.acceptedAt = estimate.acceptedAt === undefined ? null : estimate.acceptedAt;
        this.declinedAt = estimate.declinedAt === undefined ? null : estimate.declinedAt;
        if (estimate.items === undefined) {
            this.items = [];
        } else {
            this.items = estimate.items.map(item => new EstimateItem(item));
        }
    }

    static calculateNetAmount(items: EstimateItem[]): number {
        let netAmount = 0;
        for (const item of items) {
            netAmount += parseInt(accounting.toFixed(item.unitPrice * item.quantity, 0));
        }

        return netAmount
    }

    getNetAmount(): number {
        return Estimate.calculateNetAmount(this.items);
    }

    static calculateNetAmountsByTaxes(items: EstimateItem[]): number[] {
        let netAmounts:number[] = [];

        for (const item of items) {
            let currentNetAmount: number = netAmounts[item.taxRate];
            if (currentNetAmount == null) {
                currentNetAmount = parseInt(accounting.toFixed(item.unitPrice * item.quantity, 0));
            } else {
                currentNetAmount += parseInt(accounting.toFixed(item.unitPrice * item.quantity, 0));
            }
            netAmounts[item.taxRate] = currentNetAmount
        }

        return netAmounts
    }

    getNetAmountsByTaxes(): number[] {
        return Estimate.calculateNetAmountsByTaxes(this.items)
    }

    static calculateTaxValues(items: EstimateItem[]) {
        let netAmounts = Estimate.calculateNetAmountsByTaxes(items);
        let taxValuesList: {taxRate:number, taxAmount:string, taxAmountRaw:number, netAmount:string}[] = [];

        for (let key in netAmounts) {
            let taxRateRaw = parseInt(key);
            let taxRate = taxRateRaw / 100;
            let taxAmount = parseInt(accounting.toFixed(netAmounts[key] * taxRate, 0));
            let taxAmountParsed = taxAmount / 100;
            let netAmountParsed = netAmounts[key] / 100;

            let taxAmounts = {
                taxRate: taxRateRaw,
                taxAmount: accounting.formatMoney(taxAmountParsed, "", 2, ".", ","),
                taxAmountRaw: taxAmount,
                netAmount: accounting.formatMoney(netAmountParsed, "", 2, ".", ","),
            };

            taxValuesList.push(taxAmounts);
        }

        return taxValuesList;
    }

    getTaxValues() {
        return Estimate.calculateTaxValues(this.items);
    }

    static calculateTotalAmount(items: EstimateItem[]): number {
        let netAmount = Estimate.calculateNetAmount(items);
        let taxValues = Estimate.calculateTaxValues(items);

        let totalTaxAmount = 0;

        for (const taxValue of taxValues) {
            totalTaxAmount += taxValue.taxAmountRaw;
        }

        return netAmount + totalTaxAmount;
    }

    getTotalAmount(): number {
        return Estimate.calculateTotalAmount(this.items)
    }

    getValidityDateFromNow() {
        if (moment(moment().format("YYYY-MM-DD")).isSame(this.validityDate)) {
            return "Läuft heute ab";
        } else if (moment(moment().format("YYYY-MM-DD")).add(1, 'days').isSame(this.validityDate)) {
            return "Läuft morgen ab";
        } else if (moment(moment().format("YYYY-MM-DD")).isAfter(this.validityDate)) {
            return "Abgelaufen";
        } else {
            let a = moment(this.validityDate).add(1, 'days');
            let b = moment();
            return "Noch " + a.diff(b, 'days') + " Tage";
        }
    }

    isExpired() {
        return moment(moment().format("YYYY-MM-DD")).isAfter(this.validityDate);
    }

    getEstimateNumber() {
        return this.numberPrefix + this.number;
    }
};