import React, {useEffect} from 'react';
import {withRouter} from "react-router-dom";
import {Button, Card, Typography} from "antd";
import EstimateItemsTable from "../../components/Estimate/ItemsTable";
import Estimate from "../../models/Estimate";
import CalculationList from "../../components/Estimate/CalculationList";
import EstimateAlertBar from "../../components/Estimate/AlertBar";

const styles = require('./index.module.less');

const {Title, Text} = Typography;

function EstimateShowPage(props) {
    let estimate = new Estimate(props.estimate);

    useEffect(() => {
        document.title = "Angebot " + estimate.getEstimateNumber();
    }, [estimate]);

    return (
        <div>
            <EstimateAlertBar estimate={estimate} />
            <Title level={3} className={styles.headline}>Angebot {estimate.getEstimateNumber()}</Title>
            <div className={styles.logoContainer}>
                {estimate.company?.logoUrl &&
                <img style={{width: '100%', height: 'auto', maxHeight: 100, maxWidth: 256}} src={estimate.company?.logoUrl} alt="Logo" />}
            </div>
            <div style={{clear: 'both', marginBottom: 24}} />
            <Text>Ein Angebot an <strong>{estimate.customer?.getName()}</strong> von <strong>{estimate.company?.companyName}</strong></Text>
            <div style={{marginTop: 16, marginBottom: 16, textAlign: 'right'}}>
                <Button icon="download" onClick={() => {window.open(estimate.estimatePdf!, "_blank");}}>Download PDF</Button>
            </div>
            <EstimateItemsTable estimate={estimate}/>
            <Card style={{marginTop: 24, marginBottom: 48, float: "right"}}>
                <CalculationList estimateItems={estimate.items} />
            </Card>
        </div>
    );
}

export default withRouter(EstimateShowPage);