import React, {useCallback, useEffect} from 'react';
import accounting from "accounting";
import {List, Typography} from "antd";
import Estimate from "../../models/Estimate";
import EstimateItem from "../../models/EstimateItem";

const {Text, Title} = Typography;

export interface EstimateCalculationListProps {
    estimateItems: EstimateItem[],
    style?: React.CSSProperties,
}

function EstimateCalculationList(props: EstimateCalculationListProps) {
    const [dataSource, setDataSource] = React.useState<{type: string, title: string, amount: string}[]>([]);

    const updateInvoiceItemsState = useCallback((estimateItems: EstimateItem[]) => {
        const netAmount = Estimate.calculateNetAmount(estimateItems);
        const taxValues = Estimate.calculateTaxValues(estimateItems);
        const totalAmount = Estimate.calculateTotalAmount(estimateItems);

        let newDataSource: any[] = [];
        newDataSource.push({type: "net", title: "Nettobetrag", amount: accounting.formatMoney(netAmount / 100, "", 2, ".", ",") + " €"});
        for (const taxValue of taxValues) {
            if (taxValues.length === 1 || (taxValues.length > 1 && taxValue.taxRate !== 0)) {
                newDataSource.push({
                    type: "tax",
                    title: `Umsatzsteuer ${taxValue.taxRate} %`,
                    amount: taxValue.taxAmount + " €",
                });
            }
        }
        newDataSource.push({type: "total", title: "Gesamtbetrag", amount: accounting.formatMoney(totalAmount / 100, "", 2, ".", ",") + " €"});
        setDataSource(newDataSource);
    }, []);

    useEffect(() => {
        updateInvoiceItemsState(props.estimateItems);
    }, [props.estimateItems, updateInvoiceItemsState]);

    return (
        <List
            style={props.style}
            dataSource={dataSource}
            renderItem={item => (
                    <List.Item>
                            <div style={{display:"flex", flex: 1, marginRight: 16}}>
                                {item.type === "total" && <Title level={4}>{item.title}</Title>}
                                {item.type !== "total" && <Text>{item.title}</Text>}
                            </div>
                            <div>
                                {item.type === "total" && <Title level={4}>{item.amount}</Title>}
                                {item.type !== "total" && <Text>{item.amount}</Text>}
                            </div>
                    </List.Item>
                )}
        />
    );
}

export default React.memo(EstimateCalculationList, function areEqual(prevProps, nextProps) {
    return JSON.stringify(prevProps) === JSON.stringify(nextProps);
});