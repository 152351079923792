import React from 'react';
import {Button, Modal, message, Icon} from 'antd';
import 'moment/locale/de';
import AcceptEstimateMutation from "../../../mutations/AcceptEstimateMutation";
import DeclineEstimateMutation from "../../../mutations/DeclineEstimateMutation";
import {EstimateDeclineReason} from "../../../mutations/__generated__/DeclineEstimateMutation.graphql";
import Estimate from "../../../models/Estimate";
import DeclineModalForm from "../../../components/Estimate/DeclineModalForm";

const styles = require('./index.module.less');
const {confirm} = Modal;

export interface ActionHeaderProps {
    estimate: Estimate;
}

function ActionHeader(props: ActionHeaderProps) {
    const [showModal, setShowModal] = React.useState<boolean>(false);
    const [confirmLoading, setConfirmLoading] = React.useState(false);
    const declineFormRef = React.useRef<any>();

    const measuredRef = React.useCallback(node => {
        if (node !== null) {
            declineFormRef.current = node.getForm();
        }
    }, []);

    function showConfirm() {
        confirm({
            title: 'Möchten Sie das Angebot wirklich annehmen?',
            okText: 'Annehmen',
            cancelText: 'Abbrechen',
            onOk() {
                handleAccept();
            },
        });
    }

    function onShowModal() {
        setShowModal(true);
    }

    function onCloseModal() {
        setShowModal(false);
    }

    function handleAccept() {
        AcceptEstimateMutation(props.estimate.webToken, (estimate) => {
            if (estimate) {
                message.success('Angebot erfolgreich angenommen');
            } else {
                message.error('Fehler beim Annehmen des Angebots');
            }
        });
    }

    function handleDecline() {
        const form = declineFormRef.current;
        form.validateFields((err, values) => {
            if (err) {
                return;
            }
            setConfirmLoading(true);
            DeclineEstimateMutation(props.estimate.webToken, values.reason as EstimateDeclineReason, values.note, (estimate) => {
                form.resetFields();
                setShowModal(false);
                setConfirmLoading(false);

                if (estimate) {
                    message.success('Angebot erfolgreich abgelehnt');
                } else {
                    message.error('Fehler beim Ablehnen des Angebots');
                }
            });
        });
    }

    return (
        <>
            <Button className={styles.btnAccept} type="primary" style={{marginRight: 8}}
                    onClick={showConfirm}>
                <Icon className={styles.btnIcon} type="check" />
                Annehmen
            </Button>
            <Button className={styles.btnDecline} type="primary"
                    onClick={onShowModal}>
                <Icon className={styles.btnIcon} type="cross" />
                Ablehnen
            </Button>
            <DeclineModalForm
                ref={measuredRef}
                visible={showModal}
                onDecline={handleDecline}
                onCancel={onCloseModal}
                confirmLoading={confirmLoading}
            />
        </>
    );
}

export default ActionHeader