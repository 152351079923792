import React from 'react';
import {Link} from "react-router-dom";
import {Menu, Icon, Drawer} from 'antd';

const styles = require('./index.module.less');

export interface MobileNavigationDrawerProps {}

function MobileNavigationDrawer(props: MobileNavigationDrawerProps) {
    const [visible, setVisible] = React.useState<boolean>(false);

    const showDrawer = () => {
        setVisible(true);
    };

    const onClose = () => {
        setVisible(false);
    };

    const onMenuItemClick = () => {
        setVisible(false);
    };

    return (
        <>
            <Icon className={styles.menuIcon} type="menu" onClick={showDrawer} />
            <Drawer
                placement="left"
                closable={false}
                onClose={onClose}
                visible={visible}
            >
                <Menu className={styles.menu} defaultSelectedKeys={['1']} mode="inline">
                    <Menu.Item key="1" onClick={onMenuItemClick}>
                        <Link to="/estimate">
                            <Icon type="file" />
                            <span>Angebot</span>
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="2" onClick={onMenuItemClick}>
                        <Link to="/contact">
                            <Icon type="mail" />
                            <span>Kontakt</span>
                        </Link>
                    </Menu.Item>
                </Menu>
            </Drawer>
        </>
    );
}

export default MobileNavigationDrawer