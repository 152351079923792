import {
    commitMutation
} from 'react-relay'
import graphql from 'babel-plugin-relay/macro';
import environment from '../Environment'
import {AcceptEstimateMutation, AcceptEstimateMutationVariables} from "./__generated__/AcceptEstimateMutation.graphql";

const mutation = graphql`mutation AcceptEstimateMutation($input: AcceptEstimateInput!) {
    acceptEstimate(input: $input) {
      estimate {
        id
        status
      }
    }
  }
`;

export default (webToken, callback) => {
    const variables: AcceptEstimateMutationVariables = {
        input: {
            webToken: webToken,
        }
    };

    commitMutation<AcceptEstimateMutation>(
        environment,
        {
            mutation,
            variables,
            onCompleted: (response) => {
                const estimate = response.acceptEstimate?.estimate;
                callback(estimate)
            },
            onError: err => console.error(err),
        },
    )
}